import './App.css';
import {CssBaseline, Container, Toolbar, AppBar, Button, Box, Stack, Typography} from "@mui/material";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import {Header, Footer, CartTwo} from "./components";
import {About,Books, Cart, Checkout, Gallery, Home, OrderCompleted, Press, Publications, Videos} from "./pages";
import {menuLinks} from "./data/demo";
import {theme} from "./config/theme";
import React, {useEffect, useState} from "react";
import {useCartContext} from "./context/CartContext";
import BGPattern from "./data/images/BGPattern.svg";

function App() {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <div style={{ backgroundImage: `url(${BGPattern})`, backgroundSize: 'cover', backgroundRepeat: "no-repeat" }}>
                <Container maxWidth="lg">
                    <Header menuLinks={menuLinks} />
                </Container>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/gallery' element={<Gallery />} />
                    {/* <Route path='/books' element={<Books />} />
                    <Route path='/press' element={<Press />} />
                    <Route path='/publications' element={<Publications />} />
                    <Route path='/videos' element={<Videos />} />
                    <Route path='/cart' element={<Cart />} />
                    <Route path='/checkout' element={<Checkout />} />
                    <Route path='/order-completed' element={<OrderCompleted />} /> */}
                </Routes>
                <Container maxWidth="lg" style={{ backgroundColor: "#FFF" }}>
                    <Footer />
                </Container>
                <CartTwo/>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      );
}

export default App;
