import React from 'react';
import {Button, Stack} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const GalleryPages = (props) => {
    return (
        <Stack direction="row" justifyContent="space-between" mt={1} p={1}>

            <Stack direction="row" spacing={0}>
                {[...Array(props.totalGalleryPages < 5 ? props.totalGalleryPages : 4)].map((el, i) => (<CircleIcon key={`${i} `} sx={{ fontSize: "0.6rem !important", color: (i+1) == props.activeGalleryPage ? "#FF6200" : "#E4E8EC" }} />))}
            </Stack>

            <Stack direction="row" spacing={1}>
                <Button onClick={() => props.getPhotoGalleryByPage(props.prevPhotoGalleryPage)} variant="contained" disabled={props.prevPhotoGalleryPage == null ? true : false}>
                    <NavigateBeforeIcon fontSize="small" />
                </Button>

                <Button onClick={() => props.getPhotoGalleryByPage(props.nextPhotoGalleryPage)} variant="contained" disabled={props.nextPhotoGalleryPage == null ? true : false}>
                    <NavigateNextIcon fontSize="small" />
                </Button>
            </Stack>

        </Stack>
    )
}

export default GalleryPages;
