import * as React from 'react';
import PropTypes from 'prop-types';
import {Toolbar, Typography, Box, Button} from "@mui/material";
import {Navbar} from '../';
import SocialMedia from '../SocialMedia';
import YouTube from '../../data/images/SocialMedia/YouTube.svg';
import Facebook from '../../data/images/SocialMedia/facebook.png';
import Twitter from '../../data/images/SocialMedia/Twitter.svg';
import {socialMediaUrl} from "../../data/demo";
import LOGO from "../../data/images/DEENCONNECT-ImamDalouer-ZamZam-Hajj-Umrah-Name.png";

const socialMediaLinks = [
    {
        title: "Facebook",
        url: 'https://www.facebook.com/imamdalouer',
        icon: Facebook
    },
    {
        title: "YouTube",
        url: 'https://www.youtube.com/channel/UCfe06KuigZlc3wtYpXJNRHw',
        icon: YouTube
    },
    // {
    //     title: "Twitter",
    //     url: socialMediaUrl.twitter,
    //     icon: Twitter
    // },
];

const Header = (props) => {
    const { menuLinks, title } = props;

    return (
        <React.Fragment>
            <Toolbar sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: "#FFF" }}>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <SocialMedia socialMedia={socialMediaLinks} />
                </Box>

                <Typography color="inherit" align="center" noWrap sx={{ flex:1 }}>
                    <img src={LOGO} alt="LOGO" style={{ height: "50px" }} />
                </Typography>

                <Toolbar>
                    <Button href="about" variant="contained" sx={{ display: { xs: 'none', sm: 'block', marginLeft: 5, textTransform: "none" } }} >
                        Contact
                    </Button>
                </Toolbar>

            </Toolbar>

            <Navbar menuLinks={menuLinks} />

        </React.Fragment>
    )
}

Header.propTypes = {
    menuLinks: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
    ).isRequired,
    //title: PropTypes.string.isRequired
}

export default Header
