import React, { useEffect, useState } from "react";
import {
  CardTwenty,
  SectionTitleTwo,
  SliderFourteen,
  PromoteBook,
  VideoCardOne,
  CardEleven,
  AboutOne,
  ImageGridFour,
  AboutTwo,
  SeoOne,
} from "../../components";
import { Container, Grid, Box, Stack, Button } from "@mui/material";
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  mediaPressGet,
  productGet,
  publicationGet,
  photoMediaGet,
  videoMediaGet,
  getApiData,
} from "../../api";
import { ToastContainer } from "react-toastify";
import CircleIcon from "@mui/icons-material/Circle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const Home = () => {
  const [publications, setPublications] = useState([]);
  const [products, setProducts] = useState([]);
  const [mediaPress, setMediaPress] = useState([]);

  const [videoGallery, setVideoGallery] = useState([]);
  const [photoGallery, setPhotoGallery] = useState([]);
  const [photoGalleryArr, setPhotoGalleryArr] = useState([]);
  const [prevPhotoGalleryPage, setPrevPhotoGalleryPage] = useState(null);
  const [nextPhotoGalleryPage, setNextPhotoGalleryPage] = useState(null);
  const [totalGalleryPages, setTotalGalleryPages] = useState(0);
  const [activeGalleryPage, setActiveGalleryPage] = useState(0);

  useEffect(() => {
    const getPhotoGallery = async () => {
      try {
        const { data } = await photoMediaGet(10, 1);
        setNextPhotoGalleryPage(data.data.next_page_url);
        setPrevPhotoGalleryPage(data.data.prev_page_url);
        setActiveGalleryPage(data.data.current_page);
        setTotalGalleryPages(data.data.last_page);
        setPhotoGallery(data.data.data);
      } catch (e) {}
    };

    getPhotoGallery();
    return () => getPhotoGallery();
  }, []);

  useEffect(() => {
    let data = [];
    for (let photo in photoGallery) data.push(photoGallery[photo].img);

    setPhotoGalleryArr(data);
  }, [photoGallery]);

  useEffect(() => {
    const getVideoGallery = async () => {
      try {
        const { data } = await videoMediaGet(3, 1);
        setVideoGallery(data.data.data);
      } catch (e) {}
    };

    getVideoGallery();
    return () => getVideoGallery();
  }, []);

  useEffect(() => {
    const getPublication = async () => {
      try {
        const { data } = await publicationGet(3, 1);
        setPublications(data.data.data);
      } catch (e) {}
    };

    getPublication();
    return () => getPublication();
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const { data } = await productGet(3, 1);
        setProducts(data.data.data);
      } catch (e) {}
    };

    getProducts();
    return () => getProducts();
  }, []);

  useEffect(() => {
    const getMediaPress = async () => {
      try {
        const { data } = await mediaPressGet(3, 1);
        setMediaPress(data.data.data);
      } catch (e) {}
    };

    getMediaPress();
    return () => getMediaPress();
  }, []);

  const getPhotoGalleryByPage = async (link = null) => {
    if (link != null) {
      try {
        const { data } = await getApiData(link + "&pageSize=10");
        setNextPhotoGalleryPage(data.data.next_page_url);
        setPrevPhotoGalleryPage(data.data.prev_page_url);
        setActiveGalleryPage(data.data.current_page);
        setTotalGalleryPages(data.data.last_page);
        setPhotoGallery(data.data.data);
      } catch (e) {}
    }
  };

  return (
    <>
      <SeoOne pageKey="home" />

      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
      <Container maxWidth="lg">
        <SliderFourteen />
      </Container>

      <Container maxWidth="lg" sx={{ pt: 5, backgroundColor: "#FFF" }}>
        {/* <div style={{ marginBottom: "40px" }}>
          <SectionTitleTwo title="Recent Books" linkTitle="All Books" link="/book" />
          <Grid container spacing={4} pt={2}>
          {products.map((product) => (
            <CardTwenty
              key={JSON.stringify(product)}
              item={product}
            />))}
          </Grid>
        </div> */}

        {/* <PromoteBook /> */}

        {/* <Box sx={{ mb:5 }}>
          <SectionTitleTwo title="Recent Videos" linkTitle="All Videos" link="/videos" />
          <Grid container spacing={4} sx={{ pt:2 }}>
          {
            videoGallery.map((video, i) => (
              <Grid item key={`${i} `} sm={12} md={6} lg={4}>
                <VideoCardOne
                  img={video?.media?.basePath+"/"+video?.media?.media_thumbnails}
                  title={video?.media?.media_title}
                  link={video?.media?.basePath+"/"+video?.media?.media_url}
                  titleColor="#0A2540"
                  ReadmoreColor="#676C7B"
                  border="none"
                  icon={<YouTubeIcon />}
                  ImgHeight="196px"
              />
              </Grid>
            ))
          }
         </Grid>

        </Box> */}

        {/* <Box sx={{ mb:5 }}>
          <SectionTitleTwo title="Recent Publications" linkTitle="All Publications" link="/publications" />
          <Grid container spacing={4} sx={{ pt:2 }}>
            {publications.map((publication) => (
              <Grid item key={JSON.stringify(publication)} sm={12} md={6} lg={4}>
                <CardEleven
                  img={publication.pub_thumbnail_url}
                  title={publication.pub_title}
                  link={publication.pub_details_url}
                  titleColor="#0B132B"
                  buttonLabel="Read More"
                  ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#0D1267"
                  border=""
              />
              </Grid>
            ))}
          </Grid>
        </Box> */}

        <AboutOne />

        {/* <Box sx={{ mb:4 }}>
          <SectionTitleTwo title="Photo Gallery" />
          <ImageGridFour imgGridData={photoGallery} images={photoGalleryArr} />
          <Stack direction="row" justifyContent="space-between" mt={1} p={1}>

            <Stack direction="row" spacing={0}>
              {[...Array(totalGalleryPages < 5 ? totalGalleryPages : 4)].map((el, i) => (<CircleIcon key={`${i} `} sx={{ fontSize: "0.6rem !important", color: (i+1) === activeGalleryPage ? "#FF6200" : "#E4E8EC" }} />))}
            </Stack>

            <Stack direction="row" spacing={1}>
              <Button onClick={() => getPhotoGalleryByPage(prevPhotoGalleryPage)} variant="contained" disabled={prevPhotoGalleryPage == null ? true : false}>
                <NavigateBeforeIcon fontSize="small" />
              </Button>

              <Button onClick={() => getPhotoGalleryByPage(nextPhotoGalleryPage)} variant="contained" disabled={nextPhotoGalleryPage == null ? true : false}>
                <NavigateNextIcon fontSize="small" />
              </Button>
            </Stack>

          </Stack>
        </Box> */}

        <AboutTwo />

        {/* <Box>
          <SectionTitleTwo title="Recent Media" linkTitle="All Press" link="/press" />
          <Grid container spacing={4} sx={{ pt:2 }}>
            {mediaPress.map((press) => (
              <Grid item key={JSON.stringify(press)} sm={12} md={6} lg={4}>
                <CardEleven
                  img={press.mp_thumbnail_url}
                  title={press.mp_title}
                  link={press.mp_details_url}
                  titleColor="#0B132B"
                  buttonLabel="Read More"
                  ReadmoreIcon={<ArrowForwardRoundedIcon />}
                  ReadmoreColor="#0D1267"
                  border=""
              />
              </Grid>
            ))}
          </Grid>

        </Box> */}
      </Container>
    </>
  );
};

export default Home;
