
import Instagram from './images/SocialMedia/Instagram.svg';
import Twitter from './images/SocialMedia/Twitter.svg';
import WhatsApp from './images/SocialMedia/WhatsApp.svg';
import YouTube from './images/SocialMedia/YouTube.svg';
import Facebbok from './images/SocialMedia/facebook.png';

import Book1 from "./images/books/Book1.png";
import Book1_2x from "./images/books/Book1@2x.png";
import Book2 from "./images/books/Book2.png";
import Book2_2x from "./images/books/Book2@2x.png";
import Book3 from "./images/books/Book3.png";
import Book3_2x from "./images/books/Book3@2x.png";

import Video1 from "./images/videos/video.png";
import Video1_2x from "./images/videos/video@2x.png";
import Video2 from "./images/videos/video2.png";
import Video2_2x from "./images/videos/video2@2x.png";
import Video3 from "./images/videos/video3.png";
import Video3_2x from "./images/videos/video3@2x.png";

import pub1 from "./images/publications/pub-1.png";
import pub1_2x from "./images/publications/pub-1@2x.png";
import pub2 from "./images/publications/pub-2.png";
import pub2_2x from "./images/publications/pub-2@2x.png";
import pub3 from "./images/publications/pub-3.png";
import pub3_2x from "./images/publications/pub-3@2x.png";

import galleryImg1 from "./images/gallery/img-1.png";
import galleryImg1_2x from "./images/gallery/img-1@2x.png";
import galleryImg2 from "./images/gallery/img-2.png";
import galleryImg2_2x from "./images/gallery/img-2@2x.png";
import galleryImg3 from "./images/gallery/img-3.png";
import galleryImg3_2x from "./images/gallery/img-3@2x.png";
import galleryImg4 from "./images/gallery/img-4.png";
import galleryImg4_2x from "./images/gallery/img-4@2x.png";
import galleryImg5 from "./images/gallery/img-5.png";
import galleryImg5_2x from "./images/gallery/img-5@2x.png";

import pressImg1 from "./images/press/press1.png";
import pressImg1_2x from "./images/press/press1@2x.png";
import pressImg2 from "./images/press/press2.png";
import pressImg2_2x from "./images/press/press2@2x.png";
import pressImg3 from "./images/press/press3.png";
import pressImg3_2x from "./images/press/press3@2x.png";

export const themeColors = {
  primary: "#F86635",
  white: "#fff",
  lightGrey: "#A4A7B0",
  darkGrey: "#676C7B",
  darkBlue: "#0D1267"
};

export const fontWeight = {
  light: "300",
  regular: "400",
  medium: "500",
  semiBold: "600"
}

export const menuLinks = [
  { title: 'Home', url: '/' },
  { title: 'About', url: 'about' },
  { title: 'Gallery', url: 'gallery' },
  { title: 'Hajj & Umrah', url: 'https://zamzamhajj.com/', target: "_blank" },
  { title: 'Follow Me', url: 'https://www.facebook.com/imamdalouer', target: "_blank" },
  // { title: 'Publications', url: 'publications' },
  // { title: 'Press', url: 'press' },
];
// export const menuLinks = [
//     { title: 'Home', url: '/' },
//     { title: 'Books', url: 'books' },
//     { title: 'About', url: 'about' },
//     { title: 'Videos', url: 'videos' },
//     { title: 'Publications', url: 'publications' },
//     { title: 'Gallery', url: 'gallery' },
//     { title: 'Press', url: 'press' },
// ];

export const socialMediaUrl = {
  facebook: "https://facebook.com/",
  instagram: "https://facebook.com/",
  youtube: "https://facebook.com/",
  twitter: "https://facebook.com/",
  whatsapp: "https://facebook.com/",
};

export const socialMediaLinks = [
  {
    title: "Facebook",
    url: "https://www.facebook.com/ZamzamHajjUSA/",
    icon: Instagram
  },
  {
    title: "YouTube",
    url: "https://www.youtube.com/@zamzamhajjkafela202",
    icon: Instagram
  },
  {
    title: "Twitter",
    url: "https://google.com",
    icon: Twitter
  },
  {
    title: "WhatsApp",
    url: "https://google.com",
    icon: WhatsApp
  },
];

export const homepageSocialMediaLinks = [
  {
    title: "Facebook",
    url: "https://www.facebook.com/imamdalouer",
    icon: Facebbok
  },
  {
    title: "YouTube",
    url: "https://www.youtube.com",
    icon: YouTube
  },

];

export const homePageBooks = [
  {
    id: 1,
    price: 5.99,
    currency: "$",
    title: "Prayers of the pious",
    thumbnail: Book3,
    thumbnail2x: Book3_2x,
  },
  {
    id: 2,
    price: 9.99,
    currency: "$",
    title: "40 on justice: The Prophetic Voice on Social Reforms",
    thumbnail: Book1,
    thumbnail2x: Book1_2x,
  },
  {
    id: 3,
    price: 7.99,
    currency: "$",
    title: "Repetance: Breaking the Habit of Sin",
    thumbnail: Book2,
    thumbnail2x: Book2_2x,
  }
];

export const homePageVideos = [
  {
    img: Video1,
    title: "Subay'a Al-Aslamiyya (ra): The Iddah of a Widow | The Firsts Shorts",
    link: "#"
  },
  {
    img: Video2,
    title: "Ep. 20: Allah Spoke Directly to Him | Angels in Their Presence",
    link: "#"
  },
  {
    img: Video3,
    title: "Nuaym Ibn Abdullah (ra): Redirecting History | The Firsts Shorts",
    link: "#"
  }
];

export const videoCollection = homePageVideos.concat(homePageVideos).concat(homePageVideos);

export const homePagePublications = [
  {
    img: pub1,
    title: "Why Laylatul Qadr? The Significance & Virtues of the Most Important Night of the Year",
    link: "#"
  },
  {
    img: pub2,
    title: "Playing the Taqiyya Card: Evading Intelligent Debate by Calling all Muslims Liars",
    link: "#"
  },
  {
    img: pub3,
    title: "Exploring the Faith and Identity Crisis of American Muslim Youth",
    link: "#"
  }
];
export const publicationCollection = homePagePublications.concat(homePagePublications).concat(homePagePublications);


export const homePageGalleryAllImgs = [
  galleryImg1,
  galleryImg2,
  galleryImg3,
  galleryImg4,
  galleryImg5,
  galleryImg1,
  galleryImg2,
  galleryImg3,
  galleryImg4,
  galleryImg5,
];

export const homePageGalleryImgs = [
  { img: galleryImg1 },
  { img: galleryImg2 },
  { img: galleryImg3 },
  { img: galleryImg4 },
  { img: galleryImg5 },
  { img: galleryImg1 },
  { img: galleryImg2 },
  { img: galleryImg3 },
  { img: galleryImg4 },
  { img: galleryImg5 },
];

export const homePagePress = [
  {
    img: pressImg1,
    title: "Like India, Sri Lanka is using coronavirus to stigmatise Muslims",
    link: "#"
  },
  {
    img: pressImg2,
    title: "Malcolm X is still misunderstood – and misused",
    link: "#"
  },
  {
    img: pressImg3,
    title: "Don’t forget the Uighur amid the coronavirus crisis",
    link: "#"
  }
];

export const pressCollection = homePagePress.concat(homePagePress).concat(homePagePress);

