import React, {createContext, useContext, useState, useEffect} from "react";
import { applyCoupon } from "../api";
import {CouponCodeTxt,totalPriceTxt,totalQtyTxt,cartItemsTxt} from "./../data/constatnt"

const CartContext = createContext();

export const CartContextProvider = ({children}) => 
{    
    const [cartItems, setCartItems] = useState([]);
    const [totalQty, setTotalQty] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0.00);
    const [couponDiscount, setCouponDiscount] = useState(0.00);
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponErrorTxt, setCouponErrorTxt] = useState("");

    useEffect(() => {
        const fetchCart = async () => {
            const items = localStorage.getItem(cartItemsTxt);
            const qty = localStorage.getItem(totalQtyTxt);
            const price = localStorage.getItem(totalPriceTxt);
            setCartItems(items ? JSON.parse(items) : cartItems);
            setTotalQty(qty ? parseInt(qty) : totalQty);
            setTotalPrice(price ? parseFloat(price).toFixed(2) : totalPrice);
            await checkCoupon();
        }
        fetchCart(); 
    }, []);

    const checkCoupon = async () => {
        const couponCode = localStorage.getItem(CouponCodeTxt);
        if(couponCode !== null)
            await applyCouponCode(couponCode);
    }

    const addToCart = (item, qty) => {
        const items = cartItems;
        let results = items.filter((x) => (x.item.id != item.id));
        const finalizeItems = results.concat([
            {
                qty: qty,
                price: item.pr_price * qty,
                id: item.id,
                item: item
            }
        ]);
        localStorage.setItem(cartItemsTxt, JSON.stringify(finalizeItems));
        setCartItems(finalizeItems);
        calPrice(finalizeItems);
        calQty(finalizeItems);
    }

    const removeCartItem = (item) => {
        const items = cartItems;
        let results = items.filter((x) => (x.item.id != item.id));
        localStorage.setItem(cartItemsTxt, JSON.stringify(results));
        setCartItems(results);
        calPrice(results);
        calQty(results);
    }

    const calPrice = async (items) => {
        let price = items.reduce((sum, current) => (sum + current.price), 0);
        localStorage.setItem(totalPriceTxt, price+"");
        setTotalPrice(parseFloat(price).toFixed(2));
        await checkCoupon();
    }

    const calQty = (items) => {
        let qty = items.reduce((sum, current) => (sum + current.qty), 0);
        localStorage.setItem(totalQtyTxt, qty+"");
        setTotalQty(parseInt(qty));
    }

    const clearCart = () => {
        localStorage.removeItem(cartItemsTxt);
        setCartItems([]);
        localStorage.removeItem(totalPriceTxt);
        setTotalPrice(0);
        localStorage.removeItem(totalQtyTxt);
        setTotalQty(0);
        localStorage.removeItem(CouponCodeTxt);
        setCouponApplied(false);
    }

    const applyCouponCode = async (code) => {
        try {
            const {data} = await applyCoupon(code);
            const coupon = data.data;
            const price = localStorage.getItem(totalPriceTxt);
            const discount = parseFloat(price) - coupon.co_discount_price;
            setTotalPrice(discount.toFixed(2));
            localStorage.setItem(CouponCodeTxt, code);
            setCouponApplied(true);
            setCouponDiscount(coupon.co_discount_price);
            setCouponErrorTxt("");
        }
        catch(e) {
            switch(e.response.status) {
                case 422:
                    const msg = e.response.data.errors.co_code[0];
                    setCouponErrorTxt(msg.replace(" co ", " "));
                    break;
                default:
                    setCouponErrorTxt(e.response.data.message);
            }
        }
    }

    const cancelCouponCode = () => {
        localStorage.removeItem(CouponCodeTxt);
        const price = localStorage.getItem(totalPriceTxt);
        setTotalPrice((parseFloat(price)).toFixed(2));
        setCouponApplied(false);
        setCouponDiscount(0.00);
    }

    return (
        <CartContext.Provider 
          value={{
            cartItems, totalQty, totalPrice,
            addToCart, removeCartItem, clearCart, 
            applyCouponCode, cancelCouponCode,
            couponApplied, setCouponApplied,
            couponDiscount, couponErrorTxt
        }}
        >
            {children}
        </CartContext.Provider>
    );
}

export const useCartContext = () => useContext(CartContext);