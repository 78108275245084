import React, { useState, useEffect } from "react";
import { Container, Grid, Box, Typography, Stack, Avatar } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  PageTitleOne,
  ImageGridFour,
  SectionTitleTwo,
  SeoOne,
  GalleryPages,
  ContactForm,
} from "../../components";
import { fontWeight, homepageSocialMediaLinks } from "../../data/demo";
import { getApiData, photoMediaGet } from "../../api";

import aboutOne from "./../../data/images/about/about1.png";
import aboutTwo from "./../../data/images/about/about2.png";
import aboutThree from "./../../data/images/about/about3.png";
import aboutFour from "./../../data/images/about/about4.png";
import FormBG from "./../../data/images/FormBG.svg";

import "./style.css";

const About = () => {
  const [photoGallery, setPhotoGallery] = useState([]);
  const [photoGalleryArr, setPhotoGalleryArr] = useState([]);
  const [prevPhotoGalleryPage, setPrevPhotoGalleryPage] = useState(null);
  const [nextPhotoGalleryPage, setNextPhotoGalleryPage] = useState(null);
  const [totalGalleryPages, setTotalGalleryPages] = useState(0);
  const [activeGalleryPage, setActiveGalleryPage] = useState(0);

  useEffect(() => {
    const getPhotoGallery = async () => {
      try {
        const { data } = await photoMediaGet(10, 1);
        setNextPhotoGalleryPage(data.data.next_page_url);
        setPrevPhotoGalleryPage(data.data.prev_page_url);
        setActiveGalleryPage(data.data.current_page);
        setTotalGalleryPages(data.data.last_page);
        setPhotoGallery(data.data.data);
      } catch (e) {}
    };

    getPhotoGallery();
    return () => getPhotoGallery();
  }, []);

  const getPhotoGalleryByPage = async (link = null) => {
    if (link != null) {
      try {
        const { data } = await getApiData(link + "&pageSize=10");
        setNextPhotoGalleryPage(data.data.next_page_url);
        setPrevPhotoGalleryPage(data.data.prev_page_url);
        setActiveGalleryPage(data.data.current_page);
        setTotalGalleryPages(data.data.last_page);
        setPhotoGallery(data.data.data);
      } catch (e) {}
    }
  };

  useEffect(() => {
    let data = [];
    for (let photo in photoGallery) data.push(photoGallery[photo].img);

    setPhotoGalleryArr(data);
  }, [photoGallery]);

  return (
    <Container maxWidth="lg" sx={{ backgroundColor: "#FFF" }}>
      <SeoOne pageKey="about" />
      <PageTitleOne
        title="About"
        bgColor="#0D1267"
        titleColor="#FFF"
        titleFontSize={48}
        fontWeight={600}
      />

      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />

      <Grid container spacing={4} sx={{ mt: 5, mb: 5, p: 2 }}>
        <Grid item sm={12} md={7}>
          <Typography
            variant="h3"
            sx={{ fontWeight: fontWeight.semiBold, fontSize: 48, mb: 1 }}
          >
            Imam Dalouer Hossain
          </Typography>

          <Typography variant="body2" fontSize={20} mb={2}>
            Founder & Presdent (CEO)
          </Typography>

          <Stack mb={2} direction="row" spacing={2}>
            {homepageSocialMediaLinks.map((each) => (
              <a href={each.url} target='_blank'>
                <Avatar key={each.title} alt={each.title} src={each.icon} />
              </a>
            ))}
          </Stack>

          <Typography variant="body1" fontSize={16} paragraph>
            Imam Dalouer is tho formar National Prosidant of Muslim Um mah ‘of
            North America (MUNA). He served os a Shura Member and Executive
            Director of MUNA on several terms. Ho s the former Chairmen of The
            Us. Council of Muslim Organizations (USCMO) - an umbrella
            organization of American Musim organizations and Mosajd in USA.
          </Typography>

          <Typography variant="body1" fontSize={16} paragraph>
            Imam Dalouer graduated with a Mostar in Hodth from Bangladesh
            Modra-sha Board and Master in Theology and Islamic Studies from
            Islamic University of Knostia, Bangladesh. Ho has published many
            books on Islam, most notably “Taqwa and Muthag” and “Mano bono Quran
            Hadith” Ho has writton and publishod many wolk- researched articles
            and popers. Hoi a froquent speaker at islamic. vents and has merged
            as one of the renownod Islamic scholars in Muslim Community.
          </Typography>

          <Typography variant="body1" fontSize={16} paragraph>
            Imam Daloueris currently serving 0s the imam and Knatosh of Baitul
            Mamur Masjid & Community Contor in Brooklyn, USA. To know more about
            Imam Dlouor, his lectures, publications, and Upcoming events, visit
            wwwimamdaiouer com.
          </Typography>

          {/* <Typography variant="body1" fontSize={16} paragraph>
            
          </Typography> */}

          <ul className="about-us-ul">
            <li>
              Former National President Muslim Ummah of North America (MUNA)
            </li>
            <li>Khateeb, Baitul Mamur Masjid & Community Center</li>
            <li>Former Chairman US Council of Muslim Organizations (USCMO)</li>
          </ul>
        </Grid>
        <Grid item sm={12} md={5}>
          <div className="composition">
            <img
              src={aboutOne}
              alt=""
              className="img composition__photo composition__photo--p2"
            />
            <img
              src={aboutTwo}
              alt=""
              className="img composition__photo composition__photo--p1"
            />
            <img
              src={aboutThree}
              alt=""
              className="img composition__photo composition__photo--p3"
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ mb: 5, pr: 2, pt: 5, pb: 6, backgroundImage: `url(${FormBG})` }}
      >
        <Grid item sm={12} md={6}>
          <img
            src={aboutFour}
            style={{
              objectFit: "cover",
              objectPosition: "top",
              width: "100%",
              maxHeight: "554px",
            }}
            alt="About us"
          />
        </Grid>

        <Grid item sm={12} md={6}>
          <ContactForm />
        </Grid>
      </Grid>

      <Box>
        <SectionTitleTwo title="Photo Gallery" />
        <ImageGridFour imgGridData={photoGallery} images={photoGalleryArr} />
        <GalleryPages
          totalGalleryPages={totalGalleryPages}
          activeGalleryPage={activeGalleryPage}
          getPhotoGalleryByPage={getPhotoGalleryByPage}
          prevPhotoGalleryPage={prevPhotoGalleryPage}
          nextPhotoGalleryPage={nextPhotoGalleryPage}
        />
      </Box>
    </Container>
  );
};

export default About;
