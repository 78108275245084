import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const TextInputTwo = styled((props) => (
  <TextField
    InputProps={{ disableUnderline: true }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#0D1267",
    },
  },
  '& label.Mui-focused': {
    color: "#0D1267",
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: "#0D1267",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: "#0D1267",
    },
    '&:hover fieldset': {
      borderColor: "#0D1267",
    },
    '&.Mui-focused fieldset': {
      borderColor: "#0D1267",
    },
  },
}));

export default TextInputTwo;

