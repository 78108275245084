import React, {useState, useEffect} from 'react';
import { Container, Box} from "@mui/material";
import {PageTitleOne, ImageGridFour, SeoOne, GalleryPages} from "../../components";
import { photoMediaGet, getApiData } from '../../api';

const Gallery = () => {

  const [photoGallery, setPhotoGallery] = useState([]);
  const [photoGalleryArr, setPhotoGalleryArr] = useState([]);
  const [prevPhotoGalleryPage, setPrevPhotoGalleryPage] = useState(null);
  const [nextPhotoGalleryPage, setNextPhotoGalleryPage] = useState(null);
  const [totalGalleryPages, setTotalGalleryPages] = useState(0);
  const [activeGalleryPage, setActiveGalleryPage] = useState(0);

  useEffect(() => {
    const getPhotoGallery = async () => {
      try {
        const {data} = await photoMediaGet(10,1);
        setNextPhotoGalleryPage(data.data.next_page_url);
        setPrevPhotoGalleryPage(data.data.prev_page_url);
        setActiveGalleryPage(data.data.current_page);
        setTotalGalleryPages(data.data.last_page);
        setPhotoGallery(data.data.data);
      }
      catch(e) {}
    }

    getPhotoGallery();
    return () => getPhotoGallery();

  }, []);

  const getPhotoGalleryByPage = async (link = null) => {
    if(link != null) {
      try {
        const {data} = await getApiData(link+"&pageSize=10");
        setNextPhotoGalleryPage(data.data.next_page_url);
        setPrevPhotoGalleryPage(data.data.prev_page_url);
        setActiveGalleryPage(data.data.current_page);
        setTotalGalleryPages(data.data.last_page);
        setPhotoGallery(data.data.data);
      }
      catch(e) {}
    }
  }

  useEffect(() => {
    let data = [];
    for(let photo in photoGallery)
      data.push(photoGallery[photo].img);

    setPhotoGalleryArr(data);
  }, [photoGallery]);

  return (
    <Container maxWidth="lg" sx={{ backgroundColor: "#FFF" }}>
      <SeoOne pageKey='gallery' />
      <PageTitleOne
        title="Gallery"
        bgColor="#0D1267"
        titleColor="#FFF"
        titleFontSize={48}
        fontWeight={600}
      />

      <Box sx={{ mt:5 }}>
        <ImageGridFour imgGridData={photoGallery} images={photoGalleryArr} />
        <GalleryPages totalGalleryPages={totalGalleryPages} activeGalleryPage={activeGalleryPage} getPhotoGalleryByPage={getPhotoGalleryByPage} prevPhotoGalleryPage={prevPhotoGalleryPage} nextPhotoGalleryPage={nextPhotoGalleryPage}  />
      </Box>

    </Container>
  )
}

export default Gallery
