import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import {TextInputPhoneNumberTwo, TextInputTwo, ToastSuccess} from "../index";
import {contactUsStore} from "../../api";
import {toast} from "react-toastify";
import DoneSvg from "../../data/images/Done.svg";

const initializeInputs = {
    cu_first_name: "",
    cu_last_name: "",
    cu_email_address: "",
    cu_phone_no: "",
    cu_inquiry_type: "",
    cu_inquiry_message: "",
};

const ContactForm = () => {
    const [contactForm, setContactForm] = useState(initializeInputs);
    const [errorTxt, setErrorTxt] = useState(initializeInputs);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showBtn, setShowBtn] = useState(true);

    useEffect(() => {
        if(contactForm.cu_first_name.length < 2)
            setShowBtn(false);
        else if(contactForm.cu_last_name.length < 2)
            setShowBtn(false);
        else if(contactForm.cu_email_address.length < 2)
            setShowBtn(false);
        else if(contactForm.cu_inquiry_type.length < 2)
            setShowBtn(false);
        else if(contactForm.cu_inquiry_message.length < 2)
            setShowBtn(false);
        else
            setShowBtn(true);
    }, [contactForm]);

    const handleInputChange = (e) => {
        let value = e.target.value;
        if (e.target.name === "cu_inquiry_message" && contactForm.cu_inquiry_message.length >= 300) {
            setContactForm((prev) => ({...prev,cu_inquiry_message: value.substr(0,300)}))
            return;
        }
        setContactForm((prev) => ({...prev, [e.target.name]: e.target.value}));
    }

    const submitContactForm = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if(validateForm()){
            try {
                setErrorTxt(initializeInputs);
                const {data} = await contactUsStore(contactForm);
                setContactForm(initializeInputs);
                toast.success(<ToastSuccess message={"Message sent successfully"} />, {
                    icon: ({theme, type}) =>  <img src={DoneSvg} alt=""/>
                });
                setIsSubmitted(true);
            }
            catch(e) {
                let errors = e.response?.data?.errors;
                if(errors) {
                    for (let key in errors)
                        setErrorTxt((prev) => ({...prev, [key]: (errors[key][0]).replace(" cu ", " ")}));
                } // if(errors) {
            } //catch(e) {
        }// if(validateForm()){
    }



    const validateForm = () => {
        let validated = true;
        if(! contactForm.cu_first_name) {
            validated = false;
            setErrorTxt((prev) => ({...prev, cu_first_name: "The first name field is required"}));
        }
        if(! contactForm.cu_last_name) {
            validated = false;
            setErrorTxt((prev) => ({...prev, cu_last_name: "The last name field is required"}));
        }
        if(! contactForm.cu_email_address) {
            validated = false;
            setErrorTxt((prev) => ({...prev, cu_email_address: "A valid email field is required"}));
        }
        // if(! contactForm.cu_phone_no) {
        //     validated = false;
        //     setErrorTxt((prev) => ({...prev, cu_phone_no: "A valid phone no field is required"}));
        // }
        if(! contactForm.cu_inquiry_message) {
            validated = false;
            setErrorTxt((prev) => ({...prev, cu_inquiry_message: "The messge field is required"}));
        }
        if(! contactForm.cu_inquiry_type) {
            validated = false;
            setErrorTxt((prev) => ({...prev, cu_inquiry_type: "The inquiry type field is required"}));
        }
        return validated;
    }

    return (
        <Box
            sx={{
                bgcolor: "#fff",
                color: "#0D1267",
                p: 2
            }}
        >
            <Typography variant='body2' fontSize={20} mb={2} align="center" color="#OD1267">
                REACH OUT TO ME
            </Typography>

            <Box
                component="form"
                noValidate
                onSubmit={submitContactForm}
            >
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { sm: '1fr 1fr' },
                        gap: 2,
                    }}
                >
                    <Stack>
                        <TextInputTwo
                            label="First name"
                            value={contactForm.cu_first_name}
                            name="cu_first_name"
                            onChange={handleInputChange}
                            id="first-name"
                            variant="filled"
                            error={isSubmitted && contactForm.cu_first_name.length < 2 ? true : false}
                            style={{ marginTop: 11, backgroundColor: "#FFF", border: errorTxt.cu_first_name ? "2px solid #FF0033" : "" }}
                        />
                        <Typography color="#FF0033" fontSize={12} fontWeight="500">{errorTxt.cu_first_name}</Typography>
                    </Stack>

                    <Stack>
                        <TextInputTwo
                            label="Last name"
                            value={contactForm.cu_last_name}
                            name="cu_last_name"
                            onChange={handleInputChange}
                            id="last-name"
                            variant="filled"
                            error={isSubmitted && contactForm.cu_last_name.length < 2 ? true : false}
                            style={{ marginTop: 11, backgroundColor: "#FFF", border: errorTxt.cu_last_name ? "2px solid #FF0033" : "" }}
                        />
                        <Typography color="#FF0033" fontSize={12} fontWeight="500">{errorTxt.cu_last_name}</Typography>
                    </Stack>

                </Box>

                <Stack>
                    <TextInputTwo
                        label="Email address"
                        value={contactForm.cu_email_address}
                        name="cu_email_address"
                        onChange={handleInputChange}
                        id="email-address"
                        variant="filled"
                        error={isSubmitted && contactForm.cu_email_address.length < 1 ? true : false}
                        style={{ marginTop: 11, backgroundColor: "#FFF", border: errorTxt.cu_email_address ? "2px solid #FF0033" : "" }}
                        fullWidth
                    />
                    <Typography color="#FF0033" fontSize={12} fontWeight="500">{errorTxt.cu_email_address}</Typography>
                </Stack>

                <Stack>

                    <TextInputPhoneNumberTwo
                        iLabel="Phone number (optional)"
                        name="cu_phone_no"
                        id="cu_phone_no"
                        onChange={handleInputChange}
                    />
                    <Typography color="#FF0033" fontSize={12} fontWeight="500">{errorTxt.cu_phone_no}</Typography>
                </Stack>

                <Stack>
                    <FormControl fullWidth sx={{ marginTop: "11px" }}>
                        <InputLabel id="demo-simple-select-label">Inquiry type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={contactForm.cu_inquiry_type}
                            sx={{ border: errorTxt.cu_last_name ? "2px solid #FF0033" : "" }}
                            label="Inquiry type"
                            name="cu_inquiry_type"
                            onChange={handleInputChange}
                            error={isSubmitted && contactForm.cu_inquiry_type.length < 10 ? true : false}
                        >
                            <MenuItem value="Option Abcd">Prayers</MenuItem>
                            <MenuItem value="Option Abc Def">Abc</MenuItem>
                            <MenuItem value="Option xyzb">xyz</MenuItem>
                        </Select>
                    </FormControl>
                    <Typography color="#FF0033" fontSize={12} fontWeight="500">{errorTxt.cu_inquiry_type}</Typography>
                </Stack>

                <Stack>

                    <div>
                        <TextInputTwo
                            label="Enter your message here..."
                            value={contactForm.cu_inquiry_message}
                            name="cu_inquiry_message"
                            onChange={handleInputChange}
                            multiline
                            rows={4}
                            id="cu_inquiry_message"
                            error={isSubmitted && contactForm.cu_inquiry_message.length < 10 ? true : false}
                            variant="filled"
                            style={{ marginTop: 11, backgroundColor: "#FFF", border: errorTxt.cu_last_name ? "2px solid #FF0033" : "" }}
                            fullWidth
                        />
                        <p style={{ color: "#A4A7B0", textAlign: "right", margin: 0, fontSize: 12 }}>CHAR {contactForm.cu_inquiry_message.length}/300</p>
                    </div>

                    <Typography color="#FF0033" fontSize={12} fontWeight="500">{errorTxt.cu_inquiry_message}</Typography>
                </Stack>

                <Button variant='contained' disabled={!showBtn} fullWidth sx={{ marginTop: "11px", textTransform: "none" }} type="submit">
                    Send Message
                </Button>

            </Box>

        </Box>
    )
}

export default ContactForm;
