import * as React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Link } from "@mui/material";
import {useEffect, useState} from "react";

const Navbar = (props) => {
    const { menuLinks } = props;
    const [url, setUrl] = useState("");
    useEffect(() => {
        let pathname = window.location.pathname;
        if (pathname !== "/")
            pathname = pathname.substring(1);
        setUrl(pathname);
    }, []);

    return (
        <Toolbar component="nav" variant="dense" sx={{ justifyContent: {sm: "flex-start", md: 'center'}, gap:2, overflowX: 'auto', backgroundColor: "#FFF" }}>
            {menuLinks.map((section) => (

                <Link target={section.target} color="inherit" noWrap key={section.title} variant="body2" href={section.url} sx={{ p: 1, flexShrink: 0, textDecoration: 'none', textTransform: "uppercase", fontWeight: "600", color: url === section.url ? "#FF6200" : "inherit" }}>
                    {section.title}
                </Link>


            ))}
        </Toolbar>
    )
}

Navbar.propTypes = {
    menuLinks: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
        }),
    ).isRequired
}

export default Navbar
