import React, { useState } from 'react';
import VideoModal from '../VideoModal/VideoModal';
import {useStateContext} from "./.././../context/ContextProvider";
import "./VideoCardOne.css"
import "../../data/fonts.css"

function VideoCardOne (props) {

  const [open, setOpen] = useState(false);
  const handleOpen  = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {VideoCardOneData} = props;
  const { screenSize } = useStateContext();
  

  return (
        <div className="VideoCardOne">
          <span className="VideoCardOneInfoContainer" onClick={handleOpen}>
            <div className="VideoCardOneboxed-grey">
              <div className={props.border}>
              <div className="VideoCardOneInner" style={{padding:props.ImgSpace,}}> 
                <div className="VideoCardOneavatar" style={{height:props.ImgHeight,}}>
                  <img src={props.img} alt="" />
                  <div className="VideoCardOnehover_over"></div>
                  <div className='videoIcon'>{props.icon}</div>
                </div>

                <div className="VideoCardOneDetails" style={{background:props.Titlebackground}}>
                  <h2 style={{color:props.titleColor}} className={props.underline}>{props.title}</h2>
                  <div className="VideoCardOneDesBox">
                    <span className="VideoCardOnereadmore" style={{color:props.ReadmoreColor}}>{props.buttonLabel} {props.ReadmoreIcon} </span>
                  </div>
                </div>
                {props.BottomLine && <div className='bottomLine' style={{background:props.BottomLineColor}}></div> }
              </div>
              </div>
              
            </div>
          </span>
          <VideoModal 
            title={props.title}
            open={open} 
            handleClose={handleClose} 
            videoLink={props.link}
            width={screenSize && screenSize > 920 ? "920px" : "300px"}
            height={screenSize && screenSize > 920 ? "500px" : "300px"}
          />
        </div>



  );
}

export default VideoCardOne;
