import React from "react";
import { Stack, Avatar } from "@mui/material";

const SocialMediaTwo = (props) => {
  return (
    <Stack sx={{ pt: 4 }} direction="row" spacing={1} justifyContent="center">
      {props.socialMedia.map((each) => (
        <a href="https://www.facebook.com/imamdalouer" target="_blank">
          <Avatar key={each.title} alt={each.title} src={each.icon} />
        </a>
      ))}
    </Stack>
  );
};

export default SocialMediaTwo;
