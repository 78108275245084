import React, {useState} from 'react';
import { Typography, Box, FormControlLabel, Checkbox, Button } from '@mui/material'
import {TextInputCondensed} from "./../../";
import {useStripe, useElements, PaymentElement,Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import {useOrderContext} from "./../../../context/OrderContext";

const stripePromise = loadStripe('pk_test_51HlWlODcEeb42Ky2cOTuopp6PiZ5r8HIEqyBVJLB4SEVmFhz4q6jaXfEL0iBndb0KqIgEBJJxicTKpf1YJ92gbZY00CrYD3T0q');


const CardPayment = (props) => {

  const {step3Completed, setStep3Completed} = useOrderContext();

  const options = {
    clientSecret: props.clientSecret,
  };

  const [errMsg, setErrMsg] = useState("");
  const [bilingAddStatus, setBilingAddStatus] = useState(false);
  const hangleBilingAddStatus = (e) => {
    if(!props.orderRequest.cs_street_address.length || !props.orderRequest.cs_city.length || !props.orderRequest.cs_zipcode.length)
    {
      setErrMsg("Shipping information is not filled.");
      return;
    }
    setBilingAddStatus(!bilingAddStatus);
  };

  if(step3Completed) {
    return (<></>)
  }

  return (
    <>
      <Typography fontSize={20} fontWeight={"600"} color="#0B132B" mb={1}>Card information</Typography>


      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>

      <Typography fontSize={20} fontWeight={"600"} color="#0B132B" mt={3} mb={1}>Billing Address</Typography>
      <FormControlLabel control={<Checkbox checked={bilingAddStatus}  onChange={hangleBilingAddStatus} color="success" />} label="Same as shipping address" />
      
      {bilingAddStatus ?
        <>
          <Typography>{props.orderRequest.cs_street_address}</Typography>
          <Typography>{props.orderRequest.cs_city}, {props.orderRequest.cs_zipcode}</Typography>
        </>
        : 
        <>
        {errMsg.length > 0 ? <Typography fontSize={14} fontWeight="500" color="red">{errMsg}</Typography> : <></>}
        <Box
            sx={{
            display: 'grid',
            gridTemplateColumns: { sm: '1fr 1fr' },
            gap: 2,
            }}
        >
            <TextInputCondensed
                label="First Name"
                id="first-name"
                type="text"
                required={true}
            />

            <TextInputCondensed
                label="Last Name"
                id="last-name"
                type="text"
                required={true}
            />

        </Box>

        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '60% 40%' },
                gap: 2,
            }}
        >
            <TextInputCondensed
                label="Street Address"
                id="street-address"
                type="text"
                required={true}
            />

            <TextInputCondensed
                label="Apt/Floor (Optional)"
                id="apt-floor"
                type="text"
                required={false}
            />
            
        </Box>

        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                gap: 2,
            }}
        >
            <TextInputCondensed
                label="City/Town"
                id="city-town"
                type="text"
                required={true}
            />

            <TextInputCondensed
                label="State/Province"
                id="state-province"
                type="text"
                required={true}
            />

            <TextInputCondensed
                label="Zip/Postcode"
                id="zip-postcode"
                type="text"
                required={true}
            />

        </Box>
        </>
      }
    </>
  )
}

const CheckoutForm = () => {

  const {step3Completed, setStep3Completed} = useOrderContext();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://imamdalouer.com/",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      setStep3Completed(true);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button type='submit' disabled={!stripe} variant="contained" sx={{ textTransform: "none", mt: 3 }}>Pay Now</Button>
    </form>
  );
};

export default CardPayment