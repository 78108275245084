import React, {useEffect, useState} from 'react';
import {Box, Button, Stack, Toolbar, Typography} from "@mui/material";
import {useCartContext} from "../../context/CartContext";

const CartTwo = () => {
    const { totalPrice, totalQty } = useCartContext();
    const [showCart, setShowCart] = useState(true);

    useEffect(() => {
        const href = window.location.href;
        if (href.includes("cart") || href.includes("checkout")) {
            setShowCart(false);
        }
    }, [])

    if(!showCart || !totalQty || totalQty < 1) {
        return (<></>);
    }

    return (
        <div className="footer-cart-container">
            <div className="footer-cart-bar">
                <Toolbar>
                    <Box width="100%" sx={{ display: "flex", flexDirection: "row", justifyContent:'flex-end', gap: 2 }}>

                        <Stack>
                            <Typography variant="body2" fontWeight="500" fontSize={12} >{totalQty + " items"}</Typography>
                            <Typography variant="body2" fontWeight="700" fontSize={14} >{ "$" + totalPrice}</Typography>
                        </Stack>

                        <Button href="cart" variant="outlined" sx={{   textTransform: "none" }} >
                            GO TO CART
                        </Button>
                        <Button href="checkout" variant="contained" sx={{  textTransform: "none" }} >
                            Checkout
                        </Button>
                    </Box>
                </Toolbar>
            </div>
        </div>
    )
}

export default CartTwo
