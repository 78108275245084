import React, { useEffect, useState } from 'react';
import SliderBG from "./../../data/images/SliderBG.svg";
import { fontWeight, themeColors } from '../../data/demo';
import { Grid, Typography, Button, Toolbar } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { mainBannerList } from '../../api';
import imamDalouer from '../../data/images/Slider@2x.png';

const SliderFourteen = (props) => {
  const [banners,setBanners] = useState([]);
  useEffect(()=>{
    const getBannerList = async () => {
      try {
        const {data} = await mainBannerList();
        setBanners(data.data);
      }
      catch(e){}
    }
    getBannerList();
    return () => getBannerList();
  },[])

  return (
    <div style={{ backgroundImage: `url(${SliderBG})`,  color: themeColors.white, backgroundSize: 'cover', backgroundRepeat: "no-repeat" }}>
      <Carousel
        // IndicatorIcon={<HorizontalRuleIcon/>}
        IndicatorIcon={false}
        activeIndicatorIconButtonProps={{
          style: {
              color: 'white' 
          }
      }}
      >
        {banners.map((banner) => (
          <Grid container spacing={3} pt={3} key={JSON.stringify(banner)} mt={0}>
            <Grid item sm={12} lg={7}>
              <div style={{ padding: "60px 40px" }}>
                <Typography variant='h2' sx={{ fontWeight: fontWeight.semiBold, fontSize: 45, marginBottom: 3 }}>
                  {/* {banner.mb_title} */}
                  Welcome to <br/> the official site of <br/> Imam Dalouer Hossain 
                </Typography>

                <Button href='https://www.facebook.com/imamdalouer' target='_blank'  size={"small"} variant="contained" endIcon={<ArrowForwardIcon />}
                    sx={{ textTransform: "none", backgroundColor: themeColors.primary, color: themeColors.white, padding: "8px 15px", fontSize: 14, fontWeight: fontWeight.medium}}>
                    {/* {banner.mb_button_label} */}
                    About Imam Dalouer
                </Button>
              </div>
            </Grid>
            <Grid item sm={12} lg={5}>
              <div style={{ padding: 10, textAlign: "center", marginBottom: 20 }}>
                {/* <img src={banner.mb_img} srcSet={banner.mb_img} alt="banner" loading="lazy" style={{ width: 392, height: 392 }} /> */}
                <img src={imamDalouer}  alt="banner" loading="lazy" style={{ width: 392, height: 392 }} />
              </div>
            </Grid>
          </Grid>
        ))}

      </Carousel>
    </div>
  )
}

export default SliderFourteen
