import { Link } from '@mui/material'
import React from 'react';

import "./style.css";

const ToastSuccess = (props) => {
  return (
    <div className="ToastSuccess">
        <span>{props.message}</span>
        <Link href={props.link}>{props.linkTitle}</Link>
    </div>
  )
}

export default ToastSuccess
