import React, {createContext, useContext, useState, useEffect} from "react";
const StateContext = createContext();

export const ContextProvider = ({children}) => {
    
    const [screenSize, setScreenSize] = useState(undefined);

    useEffect(()=> {
        const handleSizeChange = () => setScreenSize(window.innerWidth);
        window.addEventListener("resize", handleSizeChange);
        handleSizeChange();
        return () => window.removeEventListener("resize", handleSizeChange);
      }, []);

    return (
        <StateContext.Provider value={{
            screenSize
            }}>
            {children}
        </StateContext.Provider>
    );
}

export const useStateContext = () => useContext(StateContext);