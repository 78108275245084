import React, { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import seo from "./../../data/seo.json";

const SeoOne = ({pageKey = ""}) => {
    const [meta, setMeta] = useState(null);
    useEffect(() => {
        const seoArr = Object.entries(seo);
        for(let i = 0; i<seoArr.length; i++ ) {
            if(seoArr[i][0] == pageKey) {
                setMeta(seoArr[i][1]);
                break;
            }
        }
    }, []);

    if(meta == null) {
        return (<></>);
    }

    return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{ meta.title }</title>
            <meta name="description" content={meta?.description} />
            <meta name="og:title" content={meta?.og_title} />
            <meta name="og:description" content={meta?.og_description} />
        </Helmet>
    </>
    )
}

export default SeoOne