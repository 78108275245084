import React from 'react';
import { Grid, Button, Typography, Link, Divider, Toolbar } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { themeColors, fontWeight, socialMediaLinks } from "./../../data/demo";
import { Body2 } from "../";
import logo from '../../../src/data/images/AfnanInnovationLab-Logo-BW/logo-1.png';
const Heading6 = ({ text }) => {
    return (
        <Typography variant='h6' style={{ fontSize: 20, fontWeight: "600", padding: "0 10px" }}>{text}</Typography>
    )
}

const Footer = () => {
    return (
        <>
            <Toolbar sx={{ mb: 4, borderBottom: 2, borderColor: '##0D1267' }} />
            <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4}>
                    <Heading6 text={'Connect'} />
                    <ul style={{ width: 200, padding: 10, margin: 0 }}>
                        {socialMediaLinks.slice(0, 2).map((socialMedia) =>
                        (
                            <li key={socialMedia.title} style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                <Link href={socialMedia.url} target="_blank" sx={{ color: themeColors.darkGrey, fontSize: 14, fontWeight: "600" }} underline="none">{socialMedia.title}</Link>
                                <ArrowForwardIcon fontSize='small' sx={{ color: themeColors.darkGrey }} />
                            </li>
                        )
                        )}

                    </ul>

                </Grid>
                <Grid item xs={12} sm={4}>
                    <Heading6 text={'Contact'} />

                    <ul style={{ padding: 10, margin: 0 }}>
                        <li style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                            <Link href="tel:+1 (646) 494-3540" sx={{ color: themeColors.darkGrey, fontSize: 14, fontWeight: "600" }} underline="none">+1(646) 494-3540</Link>
                        </li>
                        <li style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                            <Link href="mailto:imamsuleiman@gmail.com" sx={{ color: themeColors.darkGrey, fontSize: 14, fontWeight: "600" }} underline="none">team@imamdalouer.com</Link>
                        </li>
                    </ul>

                </Grid>
                <Grid item xs={12} sm={4}>
                    <Toolbar sx={{ justifyContent: { xs: "flex-start", sm: "center" } }}>
                        <Button size={"small"} href="/about" variant="contained" endIcon={<ArrowForwardIcon />}
                            sx={{ backgroundColor: themeColors.primary, color: themeColors.white, padding: "8px 15px", fontSize: 14, fontWeight: fontWeight.medium, textTransform: "none" }}>
                            Get in Touch
                        </Button>
                    </Toolbar>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} sx={{ borderBottom: 2, borderColor: '#F86635' }}>
                <Grid item sm={12} md={8}>
                    <Toolbar sx={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", gap: 2 }}>
                        <Body2 color={themeColors.darkGrey} text="&copy; 2023 Imam Dalouer. All right reserved" />
                        <Link href="#" underline='none' sx={{ fontSize: 14, fontWeight: fontWeight.regular, color: themeColors.darkGrey }}>Terms of Survice</Link>
                        <Link href="#" underline='none' sx={{ fontSize: 14, fontWeight: fontWeight.regular, color: themeColors.darkGrey }}>Privacy Policy</Link>
                    </Toolbar>
                </Grid>
                <Grid item sm={12} md={4}>
                    <Toolbar sx={{ justifyContent: "center" }}>
                        <Typography variant="caption">
                            Developed by
                        </Typography>
                        <Typography variant='h5' sx={{ paddingLeft: 1, fontWeight: fontWeight.semiBold }}>
                            <img src={ logo} alt='afnanilab logo' />
                        </Typography>
                    </Toolbar>
                </Grid>
            </Grid>

        </>
    )
}

export default Footer
