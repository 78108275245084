import axios from "axios";
const API_URL = "https://devapi.imamdalouer.com/api/";
// const API_URL = "http://localhost:8000/api/";

const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Basic YWRtaW46YWRtaW4='
}
export const getApiData     = async (link) => axios.get(link, {headers});
export const contactUsStore = async (data) => axios.post(`${API_URL}contact-us/store`, data, {headers});

export const publicationGet = async (pageSize = 9, pageNo = 1) => axios.get(`${API_URL}publication/get?pageSize=${pageSize}&pageNo=${pageNo}`, {headers});
export const productGet     = async (pageSize = 9, pageNo = 1) => axios.get(`${API_URL}product/get?pageSize=${pageSize}&pageNo=${pageNo}`, {headers});
export const mediaPressGet  = async (pageSize = 9, pageNo = 1) => axios.get(`${API_URL}media-press/get?pageSize=${pageSize}&pageNo=${pageNo}`, {headers});
export const photoMediaGet  = async (pageSize = 9, pageNo = 1) => axios.get(`${API_URL}photo/get?pageSize=${pageSize}&pageNo=${pageNo}`, {headers});
export const videoMediaGet  = async (pageSize = 9, pageNo = 1) => axios.get(`${API_URL}video/get?pageSize=${pageSize}&pageNo=${pageNo}`, {headers});

export const mediaPressList = async () => axios.get(`${API_URL}media-press/list`, {headers});
export const mainBannerList = async () => axios.get(`${API_URL}main-banner/list`, {headers});

export const applyCoupon    = async (code) => axios.post(`${API_URL}coupon/apply`, {co_code: code}, {headers});

export const orderStoreApi  = async (orderRequest) => axios.post(`${API_URL}order/store`, orderRequest, {headers});

export const stripePaymentIntent = async (data) => axios.post(API_URL+"payment/stripe-intent", data, {headers});