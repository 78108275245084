import React from "react";
import { fontWeight, themeColors } from "../../data/demo";
import { Box, Typography, Stack, Button, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FacebookOutlined } from "@mui/icons-material";
import "./style.css";

const AboutTwo = () => {
  return (
    <Box
      sx={{
        bgcolor: themeColors.darkBlue,
        pt: 8,
        pb: 6,
        pr: 6,
        pl: 6,
        mb: 6,
      }}
    >
      <Grid container spacing={4}>
        <Grid item sm={12} md={6}>
          <img
            src={require("./../../data/images/ZamZamHajj.png")}
            className="about-img"
            alt="ZamZam Hajj"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 2,
            }}
          >
            <Typography
              component="h6"
              fontSize={20}
              fontWeight={fontWeight.medium}
              color="#F86635"
            >
              ZamZam Hajj & Umrah Services, Inc
            </Typography>

            <Typography
              component="h4"
              fontSize={34}
              fontWeight={fontWeight.semiBold}
              color="#FFF"
              lineHeight={1.15}
            >
              Annual Hajj and year-round individual and family custom Umrah
              packages available.
            </Typography>

            <Typography
              component="h6"
              fontSize={20}
              fontWeight={fontWeight.regular}
              color="#FFF"
            >
              Led by Imam Delware
            </Typography>

            <Stack
              mb={4}
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
              flexWrap={"wrap"}
            >
              <Button
                href="https://www.youtube.com/channel/UCfe06KuigZlc3wtYpXJNRHw"
                target="_blank"
                variant="contained"
                sx={{
                  textTransform: "none",
                  marginBottom: 1,
                  maxHeight: "36px",
                }}
                endIcon={<ArrowForwardIcon />}
              >
                Visit ZamZam
              </Button>
              <Button
                href="https://www.facebook.com/ZamzamHajjUSA/"
                target="_blank"
                variant="contained"
                sx={{
                  textTransform: "none",
                  marginBottom: 1,
                  backgroundColor: "#1877F2",
                  maxHeight: "36px",
                  padding: "6px 30px",
                }}
                endIcon={<FacebookOutlined />}
              >
                Find us on
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutTwo;
