import React from 'react';
import { Divider, Link, Box, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { themeColors } from '../../data/demo';


const SectionTitleTwo = ({title, linkTitle, link}) => {
  return (
    <>
    <Box sx={{ display:"flex", justifyContent: "space-between", margin:0, padding: 0, borderBottom: 2, borderColor: "#0D1267" }} >
        <Typography variant="body1" sx={{ textTransform: "uppercase", padding: "5px", backgroundColor: themeColors.darkBlue, color: themeColors.white }}>{title}</Typography>
        {linkTitle ? 
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Link href={link} underline="none">
                {linkTitle}
            </Link>
            <ArrowForwardIcon fontSize='small' sx={{ color: themeColors.primary, marginLeft: "5px" }}/>
        </div>
        :<></>}
    </Box>
    </>
  )
}

export default SectionTitleTwo