import React from 'react';
import {Box, Container, Typography, Stack, Button, Grid} from '@mui/material';
import {SocialMediaTwo} from "./../";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {fontWeight, themeColors } from '../../data/demo';

import YouTube from '../../data/images/SocialMedia/YouTube.svg';
import Facebook from '../../data/images/SocialMedia/facebook.png';
import Twitter from '../../data/images/SocialMedia/Twitter.svg';
import WhatsApp from '../../data/images/SocialMedia/WhatsApp.svg';
import Instagram from '../../data/images/SocialMedia/Instagram.svg';

import {socialMediaUrl} from "../../data/demo";
import userImg from "./../../data/images/user-1.png";
import "./style.css";

const socialMediaLinks = [
    {
        title: "Facebook",
        url: socialMediaUrl.facebook,
        icon: Facebook
    },
    // {
    //     title: "YouTube",
    //     url: socialMediaUrl.youtube,
    //     icon: YouTube
    // },
    // {
    //     title: "Twitter",
    //     url: socialMediaUrl.twitter,
    //     icon: Twitter
    // },
    // {
    //     title: "WhatsApp",
    //     url: socialMediaUrl.whatsapp,
    //     icon: WhatsApp
    // },
    // {
    //     title: "Instagram",
    //     url: socialMediaUrl.instagram,
    //     icon: Instagram
    // }
];



const AboutOne = () => {
  return (
    <>
    <Box sx={{ paddingTop: "120px", mb: 0 }}>
        <Box
          sx={{
            position: "relative",
            bgcolor: themeColors.darkBlue,
            pt: 8,
            pb: 6,
          }}
        >
            {/* style={{ position: "absolute", top:"-105px", left: "50%", marginLeft: "-105px", height: 210, width: 210 }} */}
          <img src={userImg} className="user-profile-img" />
          <Container maxWidth="md" sx={{ borderBottom: 1, borderColor: themeColors.lightGrey  }}>

            <Typography
              pt={6}
              component="h3"
              variant="h2"
              align="center"
              color="#fff"
              fontSize={48}
              fontWeight={fontWeight.semiBold}
              gutterBottom
            >
              Imam Dalouer Hossain
            </Typography>

            <Typography
              variant='body2'
              color="#fff"
              fontSize={20}
              align="center"
              mb={5}
            >
            President & CEO - ZamZam Hajj & Umrah Services, Inc
            </Typography>

            <Typography
              variant="body1"
              fontSize={16}
              align="center"
              color="#fff"
              paragraph
            >
                Imam Dalouer is the former National President of Muslim Ummah of North America (MUNA). He served as a Shura Member and Executive Director of MUNA on several terms. Former Chairmen of The U.S. Council of Muslim Organizations (USCMO)
            </Typography>

            <Stack
              mb={4}
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >

              {/* <Button href='/about' variant="contained" sx={{ textTransform: "none" }} endIcon={<ArrowForwardIcon />}>Read More</Button> */}
              <Button href='/about' variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "#FFF",
                  ":hover": {
                    backgroundColor: "#F86635",
                    color: "#FCFCFC"
                  },

                }}
                endIcon={<ArrowForwardIcon />}>Contact</Button>

            </Stack>


          </Container>

          <Container maxWidth="lg">

            <SocialMediaTwo socialMedia={socialMediaLinks}  />

            {/* <Box sx={{ pt: 4 }}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={4}>
                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2F10154009990506729%2F&width=350&show_text=true&appId=1960598044147301&height=310" style={{ width: "100%", height: 310, border:"none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </Grid>

                    <Grid item sm={12} md={6} lg={4}>
                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2F10154009990506729%2F&width=350&show_text=true&appId=1960598044147301&height=310" style={{ width: "100%", height: 310, border:"none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </Grid>

                    <Grid item sm={12} md={6} lg={4}>
                        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F20531316728%2Fposts%2F10154009990506729%2F&width=350&show_text=true&appId=1960598044147301&height=310" style={{ width: "100%", height: 310, border:"none", overflow: "hidden" }} scrolling="no" frameBorder="0" allowFullScreen allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </Grid>
                </Grid>
            </Box> */}

          </Container>

        </Box>
    </Box>
    </>
  )
}

export default AboutOne;
