import React from 'react';
import { Typography } from '@mui/material'
import {themeColors, fontWeight} from "./../../data/demo";

const Body2 = ({color, text}) => {
  return (
    <Typography variant='body2' sx={{ color: color, fontSize: "14px", fontWeight: fontWeight.regular }}>{text}</Typography>
  )
}

export default Body2