import * as React from "react";
import { Toolbar, Avatar } from "@mui/material";
import { Link } from "react-router-dom";

const SocialMedia = (props) => {
  return (
    <Toolbar
      component="nav"
      variant="dense"
      sx={{ justifyContent: "space-between", gap: 1 }}
    >
      {props.socialMedia.map((each) => (
        <a href={each.url} target='_blank'>
        <Avatar
        //   onClick={() => {
        //     <Link to={each.url}>.</Link>;
        //   }}
          key={each.title}
          alt={each.title}
          src={each.icon}
        />
        </a>
      ))}
    </Toolbar>
  );
};

export default SocialMedia;
