import React from 'react'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {SelectInputOne} from "./../";
import { Grid,FormControl,InputLabel, Select, MenuItem, Stack, Button, Box } from '@mui/material';
// import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
// import ButtonOne from '../ButtonOne/ButtonOne'
import './ModalSeven.css'

function ModalSeven(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>

          <div className='ModalSeven'>

            <div className='ModalSevenContainer' style={{ width: props.width }}>

              <div className='ModalSevenIconCtn'>
                <h2>{props.title}</h2>
                <CloseRoundedIcon className='closeIcon' onClick={() => props.handleClose()} />
              </div>

              <div className='ModalSevenMdlBody'>
                <Grid container maxWidth="lg">

                  <Grid item sm={12} md={4} sx={{ padding: "0 30px" }}>
                    <img src={props.img} alt={props.eventTitle} />
                  </Grid>

                  <Grid item sm={12} md={8} style={{ height: props.height, padding: "0 20px", overflowY: "auto" }}>

                    {props.eventTitleStatus &&
                      <h1 className='ModalSeveneventTitle'>{props.eventTitle}</h1>
                    }

                    <div className='ModalSeveneventdetails'>
                      <div className='ModalSeveneventdetailsinner'>
                        <p>{props.author}</p>
                      </div>
                      <div className='ModalSeveneventdetailsinner'>
                        <p>{props.format}</p>
                      </div>

                      <p className="ModalSevenPrice">{props.currency + props.price}</p>

                    </div>

                    <Box sx={{ maxWidth: 300 }} >

                      <SelectInputOne
                        labelId="quantity-dropdouwn-label"
                        id="quantity-dropdouwn"
                        value={props.purchaseQty}
                        onChange={props.changeMaxBuyLimit}
                        labelTxt="Quantity"
                        items={[{item:1},{item:2},{item:3},{item:4}]}
                        valueKey={"item"}
                        textKey={"item"}
                      />

                      <SelectInputOne
                        labelId="language-dropdouwn-label"
                        id="language-dropdouwn"
                        value={props.language}
                        onChange={props.changeTranslation}
                        labelTxt="Language"
                        items={props.languages}
                        valueKey={"name"}
                        textKey={"name"}
                      />


                    </Box>

                    <Stack
                        mb={4}
                        sx={{ pt: 2 }}
                        direction="row"
                        spacing={2}
                    >
                      <Button
                        variant="contained"
                        onClick={props.addToCartAction}
                        sx={{
                          backgroundColor: "#0D1267",
                          textTransform: "none",
                          width: "50%"
                        }}
                      >
                        Add to cart
                      </Button>

                      <Button variant="contained" style={{ textTransform: "none", width: "50%" }}>Buy Now</Button>

                    </Stack>

                    <div className='ModalSevenBody'>
                      {props.body}
                    </div>

                  </Grid>

                </Grid>

              </div>



            </div>

            <div className='ModalSevenFooter'>

            </div>

          </div>

        </Fade>
      </Modal>


    </div>


  )
}


export default ModalSeven;
