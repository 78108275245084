import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {ContextProvider} from "./context/ContextProvider";
import {CartContextProvider} from "./context/CartContext";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ContextProvider>
    <CartContextProvider>
      <App />
    </CartContextProvider>
  </ContextProvider>
);
