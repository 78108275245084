import { Box, Typography } from '@mui/material'
import React from 'react'

const PageTitleOne = (props) => {
    const {title,bgColor,titleColor,titleFontSize,fontWeight} = props;
  return (
    <Box sx={{ justifyContent: "center", textAlign: "center", pt:10, pb:10, backgroundColor: bgColor }}>
        <Typography fontSize={titleFontSize} color={titleColor} fontWeight={fontWeight}>
            {props.title}
        </Typography>
    </Box>
  )
}

export default PageTitleOne